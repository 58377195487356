import React, { useState } from 'react';
import './Contact.css';
import gmailLogo from '../assets/logo/gmail.png';
import instagramLogo from '../assets/logo/logo-ig-instagram-new-logo-vector-download-13.png';
import etsyLogo from '../assets/logo/etsy.png';
import facebookLogo from '../assets/logo/facebook-logo-icon-file-facebook-icon-svg-wikimedia-commons-4.png';

const Contact = () => {
  const [showCopied, setShowCopied] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const email = 'meadbyhand@gmail.com';

  const copyEmail = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(email);
      } else {
        // Fallback for browsers that don't support clipboard API
        const textArea = document.createElement('textarea');
        textArea.value = email;
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
      }
      setShowCopied(true);
      setCopyError(false);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      setCopyError(true);
      setTimeout(() => setCopyError(false), 2000);
    }
  };

  return (
    <section className="contact-section">
      <h2>Contact</h2>
      <div className="contact-content">
        <p>For inquiries about custom pieces or collaborations, please reach out:</p>
        <div className="social-links">
          <button 
            onClick={copyEmail}
            className="social-link email-button"
            title="Click to copy email address"
          >
            <img src={gmailLogo} alt="Email" className="social-icon" />
            {showCopied && (
              <div className="copy-message success">
                Email copied to clipboard!
              </div>
            )}
            {copyError && (
              <div className="copy-message error">
                Failed to copy. Please try again.
              </div>
            )}
          </button>
          <a href="https://www.instagram.com/meadbyhand" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={instagramLogo} alt="Instagram" className="social-icon" />
          </a>
          <a href="https://meadbyhandshop.etsy.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={etsyLogo} alt="Etsy" className="social-icon" />
          </a>
          <a href="https://www.facebook.com/meadbyhand" target="_blank" rel="noopener noreferrer" className="social-link">
            <img src={facebookLogo} alt="Facebook" className="social-icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact; 
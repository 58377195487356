// Import body images
import body1 from '../assets/images/body/IMG_6388.JPG';
import body2 from '../assets/images/body/IMG_6391.JPG';
import body3 from '../assets/images/body/IMG_6427.JPG';
import body4 from '../assets/images/body/IMG_1883.JPG';
import body5 from '../assets/images/body/IMG_1885.JPG';
import body6 from '../assets/images/body/IMG_1886.JPG';
import body7 from '../assets/images/body/IMG_1888.JPG';
import body8 from '../assets/images/body/IMG_6278.JPEG';
import body9 from '../assets/images/body/IMG_7725.JPEG';

// Import home images
import home1 from '../assets/images/home/home1.JPEG';
import home2 from '../assets/images/home/IMG_7718.JPEG';
import home3 from '../assets/images/home/home3.JPG';
import home4 from '../assets/images/home/home2.JPG';

export const bodyItems = [
  { image: body1, alt: 'Mead by Hand Body Image 1' },
  { image: body2, alt: 'Mead by Hand Body Image 2' },
  { image: body3, alt: 'Mead by Hand Body Image 3' },
  { image: body4, alt: 'Mead by Hand Body Image 4' },
  { image: body5, alt: 'Mead by Hand Body Image 5' },
  { image: body6, alt: 'Mead by Hand Body Image 6' },
  { image: body7, alt: 'Mead by Hand Body Image 7' },
  { image: body8, alt: 'Mead by Hand Body Image 8' },
  { image: body9, alt: 'Mead by Hand Body Image 9' }
];

export const homeItems = [
  { image: home1, alt: 'Mead by Hand Home Image 1' },
  { image: home2, alt: 'Mead by Hand Home Image 2' },
  { image: home3, alt: 'Mead by Hand Home Image 3' },
  { image: home4, alt: 'Mead by Hand Home Image 4' }
]; 
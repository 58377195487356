import React from 'react';
import { motion } from 'framer-motion';
import bioImage from '../assets/images/andrea-bio-pic.png';
import './Bio.css';

const Bio = () => {
  console.log('Bio image path:', bioImage); // Debug log
  
  return (
    <>
      <h2>About the Artist</h2>
      <p className="section-subtitle">Andrea Mead</p>
      <div className="bio-container">
        <motion.div 
          className="bio-content"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className="bio-text">
            <p>I have been exploring and experimenting with polymer clay since the early nineties. I was first inspired after seeing a pendant piece in a public market in London, England in 1992. Back then, there were only a handful of renowned polymer clay artists, very little information out there and FIMO was the only brand on the market.</p>
            <p>I wrote a letter to STAEDTLER, the company that makes FIMO and they sent me a book on Millefiori and other techniques and a lovely, personal letter. I continued my learning and made hair clips, pendants, necklaces and veneered the odd thing.</p>
            <p>The time I put into claying has hugely varied over the years but my love for it has not. I've got a small but very well stocked 'claying station' at home and these days I make one-of-a-kind pieces for the body and home. I am not into big batch production and I am not looking to build a big business. I just enjoy the quiet creativity, continuous learning and endless possibilities that come with polymer clay. I have been focused on rings and small household items and I think that is where my focus will remain. The goal is to keep making pretty things and mostly give them away, or sell them.</p>
            <p>If you're interested in buying anything, custom pieces or collaborating - please reach out. I'd love to chat.</p>
          </div>
        </motion.div>

        <motion.div 
          className="bio-image-container"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <img 
            src={bioImage}
            alt="Andrea Mead" 
            className="bio-image"
            width="375"
            height="500"
          />
        </motion.div>
      </div>
    </>
  );
};

export default Bio; 
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './BodyCarousel.css';

// Determine number of images to show based on viewport width
const getImagesPerView = () => {
  const width = window.innerWidth;
  if (width <= 600) return 1;    // Mobile
  if (width <= 900) return 2;    // Tablet
  if (width <= 1200) return 3;   // Large Tablet
  return 4;                      // Desktop
};

const BodyCarousel = ({ items = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesPerView, setImagesPerView] = useState(getImagesPerView());

  // Handle viewport resize
  useEffect(() => {
    const handleResize = () => {
      const newImagesPerView = getImagesPerView();
      setImagesPerView(newImagesPerView);
      setCurrentIndex(0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Navigation functions
  const nextImage = () => {
    if (!items.length) return;
    const maxIndex = items.length - imagesPerView;
    setCurrentIndex(prev => {
      const nextIndex = prev + 1;
      return nextIndex > maxIndex ? 0 : nextIndex;
    });
  };

  const previousImage = () => {
    if (!items.length) return;
    const maxIndex = items.length - imagesPerView;
    setCurrentIndex(prev => {
      const nextIndex = prev - 1;
      return nextIndex < 0 ? maxIndex : nextIndex;
    });
  };

  // Calculate visible images based on current index and viewport width
  const visibleImages = items.slice(currentIndex, currentIndex + imagesPerView);

  // Handle empty items array
  if (!items.length) {
    return <div className="body-carousel-container">No images available</div>;
  }

  return (
    <div className="body-carousel-container">
      <div className="body-carousel-wrapper">
        <button className="carousel-button prev" onClick={previousImage}>
          ‹
        </button>
         
        <div className="body-carousel-content">
          {visibleImages.map((item, index) => (
            <motion.div
              key={`${currentIndex}-${index}`}
              className="body-carousel-image-container"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <img 
                src={item.image} 
                alt={item.alt} 
                className="body-carousel-image"
                loading="lazy"
              />
            </motion.div>
          ))}
        </div>

        <button className="carousel-button next" onClick={nextImage}>
          ›
        </button>
      </div>
    </div>
  );
};

export default BodyCarousel; 
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import Bio from './components/Bio';
import './App.css';
import BodyCarousel from './components/BodyCarousel';
import Contact from './components/Contact';
import { bodyItems, homeItems } from './config/galleryItems';

// Create a custom theme with sophisticated colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#6B4E71', // Elegant purple
    },
    secondary: {
      main: '#C5B9BC', // Soft clay color
    },
    background: {
      default: '#FAFAFA',
    },
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontWeight: 300,
      letterSpacing: '0.2em',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 300,
      letterSpacing: '0.05em',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navigation />
          <section id="hero" className="hero-section">
            <header className="App-header">
              <p>Hand-crafted, original and upcycled polymer clay<br />objects for your body and home.</p>
            </header>
          </section>
          
          <section id="body" className="gallery-section">
            <div className="section-content">
              <h2>For the Body</h2>
              <p className="section-subtitle">One-of-a-kind bits 'n' bobs <span>for your body.</span></p>
              <BodyCarousel items={bodyItems} />
            </div>
          </section>

          <section id="home" className="gallery-section">
            <div className="section-content">
              <h2>For the Home</h2>
              <p className="section-subtitle">Flowers and fun colours for in <span>around your home.</span></p>
              <BodyCarousel items={homeItems} />
            </div>
          </section>

          <section id="about" className="gallery-section">
            <div className="section-content">
              <Bio />
            </div>
          </section>

          <section id="contact" className="contact-section">
            <Contact />
          </section>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
